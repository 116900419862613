/*
 *  Entry point for the application.
 *  Created On 06 October 2022
 */

import './index.css'
import App from './App'
import React from 'react'
import ReactDOM from 'react-dom/client'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)
