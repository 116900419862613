import React from 'react'
import { Layout } from 'antd'
import ConnectionsPage from './connections/ConnectionsPage'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

const { Header, Footer, Content } = Layout

const App = () => (
    <>
        <Layout>
            {/* <Header style={headerStyle}><Typography.Title style={introbotTitleStyle}>Introbot</Typography.Title></Header> */}
            <Header>
                <img
                    width='146'
                    src='https://uploads-ssl.webflow.com/5e85d132fc8197038db03fad/5e886f0906f6705a16caf2aa_ExtendedLogo.png'
                    srcSet='https://uploads-ssl.webflow.com/5e85d132fc8197038db03fad/5e886f0906f6705a16caf2aa_ExtendedLogo-p-500.png 500w, https://uploads-ssl.webflow.com/5e85d132fc8197038db03fad/5e886f0906f6705a16caf2aa_ExtendedLogo-p-800.png 800w, https://uploads-ssl.webflow.com/5e85d132fc8197038db03fad/5e886f0906f6705a16caf2aa_ExtendedLogo.png 930w'
                    sizes='146px'
                    alt='Introbot'
                    className='logo-image'
                />
            </Header>
            <Content style={{ minHeight: '100vh' }}>
                <BrowserRouter>
                    <Routes>
                        <Route path='/connections' element={<ConnectionsPage />} />
                        <Route path='/' element={<p>Home route</p>}>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </Content>
            <Footer></Footer>
        </Layout>
    </>
)

export default App