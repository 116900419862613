import React, {useState, useEffect} from 'react';
import { WhatsAppOutlined, LinkedinFilled } from '@ant-design/icons';
import { Table, Button, Input, Modal, Tooltip, Row, Col, Pagination } from 'antd';
import mixpanel from 'mixpanel-browser';

const PER_PAGE = 100;

function ClusterPersonsList(props) {
    
    let [persons, setPersons] = useState([])
    const [filteredPersons, setfilteredPersons] = useState([]);
    const [loading, setloading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [userInputDigits, setUserInputDigits] = useState(null);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(PER_PAGE);
    const [totalRecords, setTotalRecords] = useState(0);

    const handleChatNowBtn = (record) => {
        if (record && record.phone && record.phone.length > 8){
            setSelectedRecord(record)
            setIsModalOpen(true)
        } else {
            alert('You cannot connect with this member!')
            mixpanel.track('MS_PHONE_NUMBER_NOT_FOUND');
        }
    }

    const handleOk = () => {
        let requiredDigits = selectedRecord.phone.slice(-2)
        if(userInputDigits == requiredDigits){
            mixpanel.track('MS_PEOPLE_CONNECTED');          
            setIsModalOpen(false)
            window.open(`https://wa.me/${selectedRecord.phone}`, "_blank")
        } else {
            alert('Incorrect digits!')
            mixpanel.track('MS_INCORRECT_PHONE_NUMBER_DIGITS_ENTERED');
        }
        setUserInputDigits(null)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => {
                if(record.linkedin && !record.linkedin.includes("www")){
                    record.linkedin = "https://www." + record.linkedin;
                }
                return <>
                    <p style={{"wordBreak": "keep-all", "marginBottom": "5px"}} >{name}</p>
                    {record.linkedin? <a target="blank" href={record.linkedin}><LinkedinFilled /></a> : ""}
                </>
            },
        },
        {
            title: 'Bio',
            dataIndex: 'bio',
            key: 'bio',
            render: (bio, record) => {
                return  <>
                <p style={{"wordBreak": "break-all"}} >{bio}</p>
                {record.phone ? <Button type='primary' icon={<WhatsAppOutlined />} style={{"backgroundColor": "#00a884", "borderColor": "#00a884"}} onClick={() => handleChatNowBtn(record)}>
                    Chat now
                </Button> : ""}
                </>
            },
        }
    ]

    const fetchData = () => {
        setloading(true);
        let cluster_id = props.clusterId
        fetch(`${process.env.REACT_APP_BASE_URL}/api/people?cluster=${cluster_id}&page=${pageIndex}`)
        .then(results => results.json())
        .then(data => {
            setPersons(data.resources)
            setfilteredPersons(data.resources)
            setTotalRecords(data.count)
        })
        .finally(() => {
            setloading(false);
        })
    }
    useEffect(() => {
        fetchData();
    }, [pageIndex, pageSize])

    const onSearch = (input) => {
        input = input.toLowerCase()
        let filteredPersons = persons.filter(person => {
            return person.name?.toLowerCase().includes(input) || person.linkedin?.toLowerCase().includes(input) || person.bio?.toLowerCase().includes(input)
        })
        setfilteredPersons(filteredPersons)
    }
    return (
    <>
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Input.Search placeholder="Search" onSearch={onSearch} style={{ width: "100%" }} />
            </Col>
            <Col xs={24}>
                <Table columns={columns} dataSource={filteredPersons} loading={loading} pagination={false}  />
            </Col>
            <Col xs={24}>
                <Pagination pageSizeOptions={[100]} pageSize={pageSize} current={pageIndex} total={totalRecords} onChange={(page, size) => {setPageIndex(page); setPageSize(size)}} />
            </Col>
        </Row>
        <Modal title="Connection Confirmation" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            {selectedRecord && <Tooltip trigger={['focus']} title={`Enter last 2 digits of ${selectedRecord.name}\'s phone number`} placement="topLeft">
                <Input placeholder={`Enter last 2 digits of ${selectedRecord.name}\'s phone number`} onChange={(e) => setUserInputDigits(e.target.value)} maxLength={2} />
            </Tooltip>}
        </Modal>
    </>)
}

export default ClusterPersonsList