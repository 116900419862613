import './App.css'
import React from 'react'
import Layout from './components/Layout'
import mixpanel from 'mixpanel-browser';


let mixpanelDebug = true;
if (process.env.NODE_ENV == 'production') {
  mixpanelDebug = false
}
mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, {debug: mixpanelDebug}); 

const App = () => (
  <div className="App">
    <Layout/>
  </div>
)

export default App