import React, {useState} from 'react'
import { Table, Button } from 'antd'
import { useSearchParams } from 'react-router-dom'
import { WhatsAppOutlined, LinkedinFilled } from '@ant-design/icons';

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (name, record) => {
            if(record.linkedin && !record.linkedin.includes("www")){
                record.linkedin = "https://www." + record.linkedin;
            }
            return <>
                <p style={{"wordBreak": "keep-all", "marginBottom": "5px"}} >{name}</p>
                {record.linkedin? <a target="blank" href={record.linkedin}><LinkedinFilled /></a> : ""}
            </>
        },
    },
    {
        title: 'Bio',
        dataIndex: 'bio',
        key: 'bio',
        render: (bio, record) => {
            return <>
                <p style={{"wordBreak": "break-all", "marginBottom": "5px"}} >{bio}</p>
                <Button type='primary' icon={<WhatsAppOutlined />} style={{"backgroundColor": "#00a884", "borderColor": "#00a884"}} onClick={() => {
                    window.open(record.whatsapp_link, "_blank")
                }}>
                    Chat now
                </Button>
            </>
        },
    }
]

function Connections() {
    let [connections, setConnections] = useState([])
    let [person, setPerson] = useState({})
    let [searchParams, setSearchParams] = useSearchParams();
    const [loading, setloading] = useState(false);

    React.useEffect(() => {
        let personId = searchParams.get("personId", null)

        if(!personId){
            throw Error('Person Id missing')
        }
        
        setloading(true);
        let url = process.env.REACT_APP_BASE_URL + "/api/connections?person_id=" + personId 
        fetch(url)
            .then(results => results.json())
            .then(data => {
                setConnections(data.connections)
                setPerson(data.person)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {setloading(false)})
    }, [])

    return <Table columns={columns} dataSource={connections} loading={loading} />
}

export default Connections