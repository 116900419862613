import React, { useState } from 'react'
import Connections from './Connections';
import ClusterPersonsList from './ClusterPersonsList';
import { Row, Col, Typography, Tabs } from 'antd'
import { useSearchParams } from 'react-router-dom'


function ConnectionsPage() {
    let [searchParams, setSearchParams] = useSearchParams()
    let [clusterId, setClusterId] = useState("")
    let [selectedCluster, setSelectedCluster] = useState({})
    let [tab, setTab] = useState("connections")

    React.useEffect(() => {
        // handle when there's no person_id
        
        if (searchParams.get('cid')){
            setClusterId(searchParams.get('cid'))
        }
        if (searchParams.get('t')){
            setTab(searchParams.get('t'))
        }
    }, [])

    React.useEffect(() => {
        if(clusterId){
            fetchClusterDetails(clusterId)
        }
    }, [clusterId])

    const fetchClusterDetails = (clusterId) => {
        fetch(`${process.env.REACT_APP_BASE_URL}/api/clusters?ids=${clusterId}`)
        .then(results => results.json())
        .then(data => {
            if (data.count != 1){
                alert("Invalid cluster")
            } else {
                setSelectedCluster(data.resources[0])
            }
        })
    }

    let tabItems = [];
    
    if (selectedCluster?.name) {
        tabItems.push({
            label: `${selectedCluster?.name} Attendees`,
            key: `attendees`,
            children: <ClusterPersonsList clusterId={selectedCluster?.id} />,
        });
    }

    tabItems.push(
        {
            label: `My Connections`,
            key: 'connections',
            children: <Connections />,
        }
    );
    return (
        <>
            <Row style={{ padding: '20px' }}>
                <Col span={22} offset={1}>
                    <Tabs
                        activeKey={tab}
                        onTabClick={(key, e) => setTab(key)}
                        items={tabItems}
                    />
                </Col>
            </Row>
        </>
    )
}


export default ConnectionsPage